<template>
    <div class="fill-height">
        <v-app-bar app flat dark color="purple darken-3">
            <v-toolbar-title>{{ $env.VUE_APP_ADMIN_H1 }}</v-toolbar-title>
        </v-app-bar>
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                justify="center"
                align="center"
                class="fill-height overflow-hidden"
            >
                <v-col class="text-center">
                    <h1>Ошибка 404</h1>
                    <h2>Страница не найдена</h2>
                    <br />
                    <v-btn href="/">На главную</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
    
</style>